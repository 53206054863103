import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { getWithExpiry, setWithExpiry } from 'storage'
import Label from 'components/Label'
import StyledIconButton from 'components/StyledIconButton'
import { SpacerResponsive } from 'components/Spacer'

import ErrorImg from 'assets/img/error.png'

export default function ErrorFallback({ error }) {
  const history = useHistory()
  const isDark = useSelector(({ application }) => application.isDark)

  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error?.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000)
      }
      window.location.reload()
    } else {
      // if eror happens, clear cache and refresh page
      localStorage.clear()
    }
  }, [error])

  useEffect(() => {
    const bodyEle = document.getElementById('body')
    const htmlEle = document.getElementById('html')
    if (isDark) {
      bodyEle.style.height = '100%'
      bodyEle.style.background =
        'radial-gradient(circle at 16% 26%, rgba(11, 60, 58, 0), #003139 39%, #002a34 53%, #00252d 71%, #00252e 77%, #033239 92%), linear-gradient(to bottom, #083c39, #083c39)'
      htmlEle.style.background = '#002730'
    } else {
      bodyEle.style.background = '#f5f5f8'
      htmlEle.style.background = '#f5f5f8'
    }
  }, [isDark])

  const changeRouteToSwap = () => {
    history.push('/swap')
    window.location.reload()
  }

  return (
    <ErrorContainer>
      <SpacerResponsive size="80" />
      <Div width="100%">
        <Image src={ErrorImg} />
        <Div width="540px">
          <Label
            size="32"
            text="Something Went Wrong"
            weight="900"
            style={{ marginBottom: 20 }}
            translateId="error.fallback.title"
          />
          <Label
            size="18"
            opacity="0.5"
            weight="900"
            align="center"
            translateId="error.fallback.massage"
          >
            Something bad happened in our backend, please try to refresh the page
            later, or continue on explore the swap page.
          </Label>
          <StyledIconButton
            block
            size="21"
            icon="arrow"
            variant="primary"
            onClick={changeRouteToSwap}
            style={{ marginTop: '73px', height: '60px', maxWidth: '450px' }}
            testId="fallback"
            translateId="error.fallback.button"
          >
            Go to Swap
          </StyledIconButton>
        </Div>
      </Div>
    </ErrorContainer>
  )
}

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const Image = styled.img`
  max-width: 934px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  max-width: 100%;
  `}
`
const Div = styled.div`
  max-width: ${(props) => props.width};
  margin: 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
