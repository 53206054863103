import React from 'react'
import styled from 'styled-components'
import { Star } from '@styled-icons/evaicons-solid/Star'

import useModal from 'hooks/useModal'

import PromoModalUser from 'components/PromoModalUser'
import PromoModal from 'components/PromoModal'
import Label from 'components/Label'

const PromoButton = ({ promoLength }) => {
  const [handleReward] = useModal(<PromoModal />)

  return (
    <Div>
      <Button onClick={handleReward} testId="get-rewards">
        <StarIcon />
        <Label
          text={promoLength}
          weight="800"
          size="13"
          style={{ margin: '2px 7px 0px 7px' }}
        />
      </Button>
    </Div>
  )
}

export default PromoButton

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 4px 4px 4px;
  margin-right: 4px;
  border-radius: 15.5px;
  background-color: rgba(249, 174, 25, 0.15);
  cursor: pointer;
`
const Div = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const StarIcon = styled(Star)`
  color: #f9ae31;
  height: 20px;
  width: 20px;
`
