import {
  SET_ADD_LIQUIDITY_TOLERANCE,
  SET_SWAP_TOLERANCE,
  SET_REMOVE_LIQUIDITY_TOLERANCE,
  SET_SWAP_DEADLINE,
  SET_ADD_LIQUIDITY_DEADLINE,
  SET_REMOVE_LIQUIDITY_DEADLINE,
  SET_PROMO_MODAL,
  SET_PROMO_LENGTH,
} from 'state/settings/constants'

const initialState = {
  swapTolerance: 0.5,
  addLiquidityTolerance: 0.5,
  removeLiquidityTolerance: 0.5,
  swapDeadline: 20,
  addLiquidityDeadline: 20,
  removeLiquidityDeadline: 20,
  showPromoModal: true,
  promoLength: 0,
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SWAP_TOLERANCE:
      return {
        ...state,
        swapTolerance: action.payload,
      }
    case SET_PROMO_MODAL:
      return {
        ...state,
        showPromoModal: action.payload,
      }
    case SET_PROMO_LENGTH:
      return {
        ...state,
        promoLength: action.payload,
      }
    case SET_ADD_LIQUIDITY_TOLERANCE:
      return {
        ...state,
        addLiquidityTolerance: action.payload,
      }
    case SET_REMOVE_LIQUIDITY_TOLERANCE:
      return {
        ...state,
        removeLiquidityTolerance: action.payload,
      }
    case SET_SWAP_DEADLINE:
      return {
        ...state,
        swapDeadline: action.payload,
      }
    case SET_ADD_LIQUIDITY_DEADLINE:
      return {
        ...state,
        addLiquidityDeadline: action.payload,
      }
    case SET_REMOVE_LIQUIDITY_DEADLINE:
      return {
        ...state,
        removeLiquidityDeadline: action.payload,
      }
    default:
      return state
  }
}

export default settingsReducer
