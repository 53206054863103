import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import useWallet from 'hooks/useWallet'
import useModal from 'hooks/useModal'
import useWindowSize from 'hooks/useWindowSize'
import { useAbortableEffect } from 'monox/util'
import { setPromoModal } from 'state/settings/action'
import { SOCIALLINKS } from 'monox/constants'

import Spacer from 'components/Spacer'
import Label from 'components/Label'
import { CloseIcon } from 'components/IconButton'
import Checkbox from 'components/CheckBox'
import Modal from 'components/Modal'
import { Row, RowBetween, RowFixed } from 'components/Row'
import StyledIconButton from 'components/StyledIconButton'
import Loader from 'components/Loader'
import WalletListModal from 'components/WalletListModal'

import { getReferralId } from 'api'

import liquidityPromo from 'assets/img/liquidity-promo.webp'
import referralPromo from 'assets/img/referral-promo.webp'
import apyImg from 'assets/img/apy.svg'
import monoPromo from 'assets/img/mono-promo.webp'

const PromoCard = ({ src, text, children, size, width, labelWidth, id, top }) => {
  const align = width < 644 ? 'center' : 'left'
  const labelStyle =
    width < 644
      ? {
          display: 'flex',
          alignItems: 'center',
        }
      : { maxWidth: labelWidth }
  return (
    <Row style={{ position: 'relative', overflow: 'hidden', borderRadius: '6px' }}>
      <Image src={src} />
      <Div>
        <StyledRow>
          <div style={{ marginTop: width < 644 ? `${top}px` : '' }}>
            {Array.isArray(text) ? (
              <div>
                {text.map((t, i) => (
                  <div style={{ display: 'flex' }} key={i}>
                    {i !== 0 && (
                      <Label size={size ?? '12'} weight="800" color="#ffffff">
                        -&nbsp;
                      </Label>
                    )}
                    <Label
                      text={t}
                      size={size ?? '12'}
                      weight="800"
                      color="#ffffff"
                      style={{ maxWidth: labelWidth }}
                      align={'left'}
                      translateId={`rewards.modal.${id}.${i}`}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <Label
                text={text}
                size={size ?? '12'}
                weight="800"
                color="#ffffff"
                align={align}
                translateId={`rewards.modal.${id}`}
                style={labelStyle}
              />
            )}
            {id === 'liquidity' ? (
              <>
                <Spacer size="sm" />
                <ImageContainer>
                  <img
                    src={apyImg}
                    style={{
                      position: 'absolute',
                    }}
                    height="28"
                    alt="apyIMG"
                  />
                  <Label
                    text="Targeting 50-200% APY*"
                    size="12"
                    weight="800"
                    color="#E06559"
                    style={{
                      zIndex: '1',
                      whiteSpace: 'nowrap',
                      marginBottom: '5px',
                    }}
                  />
                </ImageContainer>
                <Spacer size="sm" />
              </>
            ) : (
              ''
            )}
          </div>
          {children}
        </StyledRow>
      </Div>
    </Row>
  )
}

const PromoModal = ({ onDismiss }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [handleUnlockClick] = useModal(<WalletListModal />)
  const modalRef = useRef(null)
  const [checked, setChecked] = useState(false)
  const { width, height } = useWindowSize()
  const top =
    height > modalRef?.current?.clientHeight
      ? (height - modalRef?.current?.clientHeight) / 2
      : 0
  const { account, chainId } = useWallet()
  const [referral, setReferral] = useState(null)

  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()

  useAbortableEffect(
    (status) => {
      if (account) {
        getReferralId({ referrer: account }, chainId).then((res) => {
          if (!status.aborted) {
            setReferral(res?.id)
          }
        })
      }
    },
    [account]
  )

  const handleConfirm = () => {
    dispatch(setPromoModal(false))
    onDismiss()
  }

  const RewardsModal = ({ responsive }) => (
    <Modal
      maxWidth="580"
      style={{ margin: '1rem auto', display: 'block' }}
      responsive={responsive}
    >
      <RowBetween
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <RowFixed style={{ alignItems: 'center' }}>
          <RowFixed>
            <CloseIcon onClick={onDismiss} data-testid="dismiss" />
          </RowFixed>
        </RowFixed>
      </RowBetween>
      {/* <PromoCard
        src={liquidityPromo}
        labelWidth={width > 644 ? '230px' : 'auto'}
        width={width}
        text={[
          'Deposit into WBTC, MATIC, ETH, USDC, USDT now to participate in our airdrop! ',
          'Testnet community members will receive a 10% bonus on their airdrop',
        ]}
        id="liquidity"
      >
        <StyledButton
          color="#dd4824"
          onClick={() => {
            history.push(`/add?network=${networkName}`)
            onDismiss()
          }}
        >
          <FormattedMessage
            id="pool.action.button.2"
            defaultMessage="Add Liquidity"
          />
        </StyledButton>
      </PromoCard>
      <Spacer /> */}
      <PromoCard
        src={referralPromo}
        labelWidth={width > 644 ? '165px' : 'auto'}
        width={width}
        size="16"
        text="Get more MONO by referring others!"
        id="referral"
        top="30"
      >
        <StyledButton
          color="#020204"
          onClick={() => {
            if (!account) {
              return handleUnlockClick()
            }
            if (referral && account) {
              return navigator?.clipboard?.writeText(
                `${window.location.origin}/home?ref=${referral}`
              )
            }
          }}
          style={{ minWidth: referral ? '110px' : '130px' }}
          data-tip={referral ? 'Copied' : ''}
          data-event="click"
          data-event-off="click"
          data-iscapture="true"
        >
          <span style={{ opacity: account && referral ? 1 : 0.5 }}>
            <FormattedMessage
              id={
                account
                  ? 'common.link.referral.label'
                  : 'common.wallet.button.not_connected'
              }
              defaultMessage={account ? 'Copy Referral Link' : 'Connect Wallet'}
            />
          </span>
          {account && referral && <ReactTooltip delayHide={1000} />}
          {!referral && account && (
            <Loader height="15" style={{ marginLeft: '10px', color: '#020204' }} />
          )}
        </StyledButton>
      </PromoCard>
      {/* <Spacer />
      <PromoCard
        src={monoPromo}
        labelWidth={width > 644 ? '230px' : 'auto'}
        width={width}
        text="Use platform features for a chance to win the community vote surprise factor to get more MONO"
        top="30"
        id="mono"
      >
        <StyledButton
          color="#4b82c0"
          onClick={() => {
            window.open(SOCIALLINKS?.learnMore)
            onDismiss()
          }}
        >
          <FormattedMessage
            id="home.cta.recently-added.button"
            defaultMessage="Learn More"
          />
        </StyledButton>
      </PromoCard> */}
      <CustomRow>
        <Checkbox
          label="Don’t show me again"
          value={checked}
          checked={checked}
          onChange={() => setChecked(!checked)}
          translateId="rewards.modal.hide"
          variant="secondary"
          size="16"
        />
        <Spacer />
        <StyledIconButton
          shadow
          variant="primary"
          icon="arrow"
          onClick={handleConfirm}
          block={width < 960 ? true : false}
          disabled={!checked}
          translateId="modal.import.token.button"
        >
          Confirm
        </StyledIconButton>
      </CustomRow>
    </Modal>
  )

  return (
    <>
      <ModalScrollView top={top}>
        <ModalCenter ref={modalRef}>
          <RewardsModal responsive={false} />
        </ModalCenter>
      </ModalScrollView>
    </>
  )
}

export default PromoModal

const ModalScrollView = styled.div`
  position: fixed;
  top: ${(props) => props.top}px;
  left: 0;
  z-index: 1060;
  display: none;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  height: -webkit-fill-available;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: block;
  padding-left: 0px;
  transition: opacity 0.15s linear;
`
const ModalCenter = styled.div`
  padding: 2rem;
  @media (max-width: 415px) {
    padding: 2rem 1rem;
  }
`
const ImageContainer = styled.div`
  width: min-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-bottom: 1px;
`

const StyledButton = styled.div`
  font-size: 14px;
  font-weight: 800;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
  min-width: 110px;
  height: 26px;
  padding: 8px;
  @media (max-width: 502px) {
    padding: 3px 10px;
  }
  border-radius: 13px;
  background-color: #fff;
  cursor: pointer;
`
const Div = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`
const StyledRow = styled(RowBetween)`
  height: 100%;
  padding: 33px;
  @media (max-width: 644px) {
    padding: 15px;
    flex-direction: column;
  }
`
const CustomRow = styled(RowBetween)`
  margin-top: 25px;
  @media (max-width: 644px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
`

const Image = styled.img`
  width: auto;
  height: 165px;
  @media (max-width: 644px) {
    height: 170px;
  }
  @media (max-width: 502px) {
    height: 180px;
  }
  @media (max-width: 384px) {
    height: 200px;
  }
`
