import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Spinner from 'react-svg-spinner'
import ReactTooltip from 'react-tooltip'
import Web3 from 'web3'
import { FormattedMessage, useIntl } from 'react-intl'
import { ManualTransWord } from 'i18n'
import MobileDetect from 'mobile-detect'
import { Check2 } from '@styled-icons/bootstrap/Check2'
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle'
import { Cross } from '@styled-icons/entypo/Cross'
import { XCircle } from '@styled-icons/boxicons-solid/XCircle'
import { ExternalLink } from '@styled-icons/evil/ExternalLink'
import { CopyOutline } from '@styled-icons/evaicons-outline/CopyOutline'

import { StyledExternalLink } from 'theme'
import TransactionUpdater from 'TransactionUpdater'
import { clearTransactions } from 'state/transaction/actions'
import { savePrivateKey } from 'state/users/actions'
import { saveProvider } from 'state/provider/actions'
import useModal from 'hooks/useModal'
import useWallet from 'hooks/useWallet'
import { walletlink } from 'monox/connectors'
import {
  getEtherscanLink,
  getNetworkScanName,
  ACTIONS,
  precise,
  TRANSACTION_STATUS,
} from 'monox/util'

import Label from 'components/Label'
import Modal from 'components/Modal'
import WalletListModal from 'components/WalletListModal'
import Divider from 'components/Divider'
import { RowBetween, RowFixed, Row, RowCenter } from 'components/Row'
import { CloseIcon } from 'components/IconButton'
import Spacer from 'components/Spacer'
import Loader from 'components/Loader'

import { getReferralId } from 'api'

const AccountModal = ({ onDismiss }) => {
  const { account, disconnect, Web3Connector, error } = useWallet()
  const WSS_URL = useSelector(({ network }) => network.WSS_URL)
  const chainId = useSelector(({ network }) => network.id)
  const NETWORK_URL = useSelector(({ network }) => network.NETWORK_URL)
  const [code, setCode] = useState(null)
  const [handleUnlockClick] = useModal(<WalletListModal />)
  const dispatch = useDispatch()
  const transactionsData = useSelector(({ transactions }) => transactions)
  const privateKey = useSelector(({ user }) => user.privateKey)
  const isDark = useSelector(({ application }) => application.isDark)
  const transactions = transactionsData[chainId] ?? []
  const intl = useIntl()
  const md = new MobileDetect(window.navigator.userAgent)

  const confirmedTransactions = useMemo(() => {
    return transactions.filter(
      (tx) =>
        tx.status === TRANSACTION_STATUS.SUCCESS ||
        tx.status === TRANSACTION_STATUS.FAIL ||
        tx.status === TRANSACTION_STATUS.PENDING ||
        tx.status === TRANSACTION_STATUS.REJECTED
    )
  }, [transactions])

  useEffect(() => {
    if (account) {
      getReferralId({ referrer: account }, chainId).then((res) => {
        setCode(res?.id)
      })
    }
  }, [account])

  const sortedTransactions = useMemo(() => {
    return confirmedTransactions.sort((a, b) => b.confirmedTime - a.confirmedTime)
  }, [confirmedTransactions])

  const handleClearTransactions = () => {
    dispatch(clearTransactions({ chainId: chainId }))
  }

  const handleDisconnect = () => {
    if (md.mobile() && Web3Connector && typeof Web3Connector.close === 'function') {
      Web3Connector.close()
      onDismiss()
      return
    }
    if (!privateKey && disconnect && typeof disconnect === 'function') disconnect()
    else {
      const new_provider = !!WSS_URL
        ? new Web3.providers.WebsocketProvider(WSS_URL, {
            reconnect: {
              auto: true,
              delay: 3000, // ms
              maxAttempts: 10,
              onTimeout: false,
            },
          })
        : new Web3.providers.HttpProvider(NETWORK_URL)
      dispatch(savePrivateKey({ chainId: chainId, privateKey: undefined }))
      dispatch(saveProvider(new_provider))
    }
    onDismiss()
  }

  let { ethereum } = window

  const address = account
    ? account
    : error?.name === 'UnsupportedChainIdError'
    ? ethereum?.selectedAddress
    : null

  return (
    <Modal width="450">
      <RowBetween style={{ alignItems: 'center' }}>
        <RowFixed>
          <Label
            text="My Account"
            size="16px"
            weight="800"
            translateId="modal.common.wallet.title"
          />
        </RowFixed>
        <RowFixed style={{ alignItems: 'center' }}>
          <RowFixed>
            <CloseIcon onClick={onDismiss} data-testid="dismiss" />
          </RowFixed>
        </RowFixed>
      </RowBetween>
      <Row
        style={{
          marginTop: '41px',
          justifyContent: 'center',
          alignItems: 'baseline',
        }}
      >
        <Label
          size="32"
          align="center"
          text={`${address?.slice(0, 6)}...${address?.slice(-4)}`}
        />
        <CopyIcon
          data-tip="copied"
          data-event="click"
          data-event-off="click"
          data-iscapture="true"
          data-testid="copy"
          onClick={() => navigator?.clipboard?.writeText(address)}
        />
        <ReactTooltip delayHide={1000}>
          <FormattedMessage id="common.link.copied" defaultMessage="copied" />
        </ReactTooltip>
      </Row>
      {!privateKey && (
        <Label
          size="13"
          weight="800"
          align="center"
          text="Connected with MetaMask"
          opacity="0.3"
          style={{ marginBottom: '20px' }}
          translateId="modal.common.wallet.sub_title"
        />
      )}
      <RowCenter>
        <Link
          href={getEtherscanLink(chainId, address)}
          target="__blank"
          className="ripple"
        >
          <FormattedMessage
            id={`modal.common.wallet.link.${getNetworkScanName(
              chainId
            )?.toLowerCase()}`}
            defaultMessage={`View on ${getNetworkScanName(chainId)}`}
          />
        </Link>
      </RowCenter>

      {/* <Referral
        data-tip={code ? 'copied' : ''}
        data-event="click"
        data-event-off="click"
        data-iscapture="true"
        data-testid="refer"
        code={code}
        onClick={() => {
          if (code) {
            return navigator?.clipboard?.writeText(
              `${window.location.origin}/home?ref=${code}`
            )
          }
        }}
      >
        <Label
          text="Copy referral code"
          size="14"
          weight="800"
          translateId="modal.common.referral.code"
          hover={code ? true : false}
          opacity={code ? 1 : 0.5}
        />
        {!code && <Loader height="15" style={{ marginLeft: '10px' }} />}
      </Referral> */}
      <ReactTooltip delayHide={1000}>
        <FormattedMessage id="common.link.copied" defaultMessage="copied" />
      </ReactTooltip>
      <RowBetween style={{ margin: '53px 0 14px 0', alignItems: 'center' }}>
        <RowFixed>
          <Label
            size="16"
            weight="800"
            text="Recent Transactions"
            translateId="modal.common.wallet.transactions"
          />
        </RowFixed>
        {sortedTransactions.length > 0 ? (
          <RowFixed>
            <Label
              size="12"
              weight="800"
              text="Clear All"
              primary
              pointer
              onClick={handleClearTransactions}
              translateId="modal.common.wallet.transactions.clear_all"
              testId="clear-all"
            />
          </RowFixed>
        ) : null}
      </RowBetween>
      <Divider />
      <TransactionsContainer>
        {sortedTransactions.map((t, i) => {
          let message
          let translateId
          let values
          const isRejected = t?.status === 'REJECTED'
          const isFailed = t?.status === 'FAIL'
          switch (t?.type) {
            case 'SWAP':
              message = `${ACTIONS[t?.type]} ${precise(t?.fromAmount, 6)} ${
                t?.fromCurrency?.symbol ?? t.fromCurrency
              } for ${t?.toAmount} ${t?.toCurrency?.symbol ?? t?.toCurrency}`
              translateId = `modal.common.wallet.transactions.swap`
              values = {
                action: ACTIONS[t?.type],
                reject: isRejected
                  ? ManualTransWord?.[intl.locale].reject
                  : isFailed
                  ? ManualTransWord?.[intl.locale].fail
                  : '',
                fromAmount: precise(t?.fromAmount, 6),
                fromCurrency: t?.fromCurrency?.symbol ?? t.fromCurrency,
                toAmount: t?.toAmount,
                toCurrency: t?.toCurrency?.symbol ?? t?.toCurrency,
              }
              break
            case 'STAKE':
            case 'UNSTAKE':
              message = `${ACTIONS[t?.type]} ${precise(t?.amount, 6)} ${
                t?.token?.symbol
              }`
              translateId = `modal.common.wallet.transactions.${ACTIONS[
                t?.type
              ]?.toLowerCase()}`
              values = {
                action: ACTIONS[t?.type],
                reject: isRejected
                  ? ManualTransWord?.[intl.locale].reject
                  : isFailed
                  ? ManualTransWord?.[intl.locale].fail
                  : '',
                amount: precise(t?.amount, 6),
                symbol: t?.token?.symbol,
              }
              break
            case 'WRAP':
              message = `${ACTIONS[t?.type]} ${precise(t?.toAmount, 6)} ${
                t?.fromCurrency === 'MATIC' && t?.toCurrency === 'WMATIC'
                  ? 'MATIC to WMATIC'
                  : 'ETH to WETH'
              }`
              translateId = `modal.common.wallet.transactions.wrap`
              values = {
                action: ACTIONS[t?.type],
                reject: isRejected
                  ? ManualTransWord?.[intl.locale].reject
                  : isFailed
                  ? ManualTransWord?.[intl.locale].fail
                  : '',
                amount: precise(t?.toAmount, 6),
                symbol:
                  t?.fromCurrency === 'MATIC' && t?.toCurrency === 'WMATIC'
                    ? 'MATIC to WMATIC'
                    : 'ETH to WETH',
              }
              break
            case 'HARVEST':
              message = `${ACTIONS[t?.type]} ${precise(t?.amount, 6)} MONO`
              translateId = `modal.common.wallet.transactions.harvest`
              values = {
                action: ACTIONS[t?.type],
                reject: isRejected
                  ? ManualTransWord?.[intl.locale].reject
                  : isFailed
                  ? ManualTransWord?.[intl.locale].fail
                  : '',
                amount: precise(t?.amount, 6),
              }
              break
            case 'UNWRAP':
              message = `${ACTIONS[t?.type]} ${precise(t?.toAmount, 6)} ${
                t?.fromCurrency === 'WMATIC' && t?.toCurrency === 'MATIC'
                  ? 'WMATIC to MATIC'
                  : 'ETH to WETH'
              }`
              translateId = `modal.common.wallet.transactions.unwrap`
              values = {
                action: ACTIONS[t?.type],
                reject: isRejected
                  ? ManualTransWord?.[intl.locale].reject
                  : isFailed
                  ? ManualTransWord?.[intl.locale].fail
                  : '',
                amount: precise(t?.toAmount, 6),
                symbol:
                  t?.fromCurrency === 'WMATIC' && t?.toCurrency === 'MATIC'
                    ? 'WMATIC to MATIC'
                    : 'ETH to WETH',
              }
              break
            case 'APPROVE':
              message = `${ACTIONS[t?.type]} ${t?.symbol ? t?.symbol : ''}`
              translateId = `modal.common.wallet.transactions.approve`
              values = {
                action: ACTIONS[t?.type],
                reject: isRejected
                  ? ManualTransWord?.[intl.locale].reject
                  : isFailed
                  ? ManualTransWord?.[intl.locale].fail
                  : '',
                symbol: t?.symbol ? t?.symbol : '',
              }
              break
            case 'BOND':
              message = `${ACTIONS[t?.type]} ${precise(Number(t?.amount), 6)} ${
                t?.token?.symbol
              }`
              translateId = `modal.common.wallet.transactions.${ACTIONS[
                t?.type
              ]?.toLowerCase()}`
              values = {
                action: ACTIONS[t?.type],
                reject: isRejected
                  ? ManualTransWord?.[intl.locale].reject
                  : isFailed
                  ? ManualTransWord?.[intl.locale].fail
                  : '',
                amount: precise(Number(t?.amount), 6),
                symbol: t?.token?.symbol,
              }
              break
            default:
              message = ` ${precise(t?.fromAmount, 6)} ${
                t?.fromCurrency?.symbol || 'vUNIT'
              }`
              translateId = `modal.common.wallet.transactions.${ACTIONS[
                t?.type
              ]?.toLowerCase()}`
              values = {
                reject: isRejected
                  ? ManualTransWord?.[intl.locale].reject
                  : isFailed
                  ? ManualTransWord?.[intl.locale].fail
                  : '',
                amount: precise(t?.fromAmount || t?.amount, 6),
                symbol: t?.fromCurrency?.symbol || 'vUNIT',
              }
          }

          return (
            <React.Fragment key={i}>
              <RowBetween style={{ margin: '13px 0', paddingRight: '8px' }}>
                <RowFixed>
                  <StyledExternalLink
                    href={
                      isRejected || !t?.tx
                        ? '#!'
                        : getEtherscanLink(
                            chainId,
                            t?.tx?.transactionHash || t?.tx,
                            'transaction'
                          )
                    }
                    target={isRejected || !t?.tx ? '' : '__blank'}
                    isRejected={isRejected || !t?.tx}
                  >
                    <Label
                      text={message}
                      translateId={translateId}
                      values={values}
                      size="13"
                      weight="800"
                      opacity={
                        t?.status !== TRANSACTION_STATUS.SUCCESS ? 0.5 : undefined
                      }
                      primary={t?.status === TRANSACTION_STATUS.SUCCESS}
                    />
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        marginBottom: '5px',
                      }}
                    >
                      {!(isRejected || !t?.tx) && <ExternalLink size="20" />}
                    </div>
                    <Spacer />
                  </StyledExternalLink>
                </RowFixed>
                <RowFixed>
                  {t?.status === TRANSACTION_STATUS.SUCCESS ? (
                    isDark ? (
                      <CheckCircleMark />
                    ) : (
                      <CheckMark />
                    )
                  ) : t?.status === TRANSACTION_STATUS.FAIL ||
                    t?.status === TRANSACTION_STATUS.REJECTED ? (
                    isDark ? (
                      <CrossCircleMark />
                    ) : (
                      <CrossMark />
                    )
                  ) : (
                    <Spinner size="20px" color="fuchsia" />
                  )}
                </RowFixed>
              </RowBetween>
              <Divider />
            </React.Fragment>
          )
        })}
      </TransactionsContainer>
      <RowBetween
        style={{
          alignItems: 'center',
          marginTop: '20px',
          justifyContent: 'center',
        }}
      >
        <CustomButton
          onClick={handleUnlockClick}
          isDark={isDark}
          data-testid="change"
        >
          <FormattedMessage
            id="modal.common.wallet.button.change"
            defaultMessage="Change"
          />
        </CustomButton>
        {Web3Connector !== walletlink && (
          <>
            <Spacer />
            <CustomButton
              secondary
              onClick={handleDisconnect}
              isDark={isDark}
              data-testid="disconnect"
            >
              <FormattedMessage
                id="modal.common.wallet.button.disconnect"
                defaultMessage="Disconnect"
              />
            </CustomButton>
          </>
        )}
      </RowBetween>
      <Spacer size="sm" />
      <TransactionUpdater />
    </Modal>
  )
}

const CheckMark = styled(Check2)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.color.primary.main};
`
const CheckCircleMark = styled(CheckCircle)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.color.primary.main};
`

const CrossMark = styled(Cross)`
  height: 20px;
  width: 20px;
  color: #ef466a;
`
const CrossCircleMark = styled(XCircle)`
  height: 20px;
  width: 20px;
  color: #ef466a;
`
const CopyIcon = styled(CopyOutline)`
  width: 20px;
  margin-left: 8px;
  opacity: 0.5;
  color: ${({ theme }) => theme.color.secondary.main};
  cursor: pointer;
`

const CustomButton = styled.div`
  color: ${(props) =>
    props.isDark
      ? '#ffffff'
      : props.secondary
      ? '#ef466a'
      : props.theme.color.primary.main};
  border-radius: ${(props) => (props.isDark ? 6 : 8)}px;
  box-shadow: ${({ theme }) => theme.shadows.button};
  background-color: ${(props) =>
    props.isDark ? 'rgba(255, 255, 255, 0.3)' : props.theme.color.background.main};
  font-size: 13px;
  padding: 8px 12px;
  font-weight: 800;
  cursor: pointer;
  width: 40%;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`
const Link = styled.a`
  background: ${({ theme }) => theme.color.background.main};
  font-weight: 800;
  font-size: 14px;
  color: ${({ theme }) => theme.color.secondary.main};
  border-radius: 27px;
  box-shadow: ${({ theme }) => theme.shadows.button};
  margin: auto;
  padding: 10px 22px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.color.primary.main};
  }
`

const TransactionsContainer = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.scroll};
    border-radius: 10px;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      max-height: 100px;
  `}
`
const Referral = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  background: ${({ theme }) => theme.color.background.main};
  width: max-content;
  margin: auto;
  border-radius: 27px;
  margin-top: 24px;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadows.button};
`

export default AccountModal
