import React, { useEffect, useRef, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import MobileDetect from 'mobile-detect'
import { List } from '@styled-icons/bootstrap/List'
import { Close } from '@styled-icons/ionicons-outline/Close'
import { toast } from 'react-toastify'

import useWallet from 'hooks/useWallet'
import useWindowSize from 'hooks/useWindowSize'
import useModal from 'hooks/useModal'
import {
  supportedChainIds,
  networks,
  DEFAULT_NETWORK_ID,
  POLYGON_CHAINS,
} from 'monox/constants'
import config from 'monox/config'
import { changeNetwork } from 'state/network/actions'
import { setPromoModal } from 'state/settings/action'
import { AccountContext } from 'contexts/AccountProvider'

import AccountButton from 'components/TopBar/components/AccountButton'
import LocaleButton from 'components/TopBar/components/LocaleButton'
import SearchBar from 'components/TopBar/components/SearchBar'
import MobileMenu from 'components/TopBar/components/MobileMenu'
import ThemeModeButton from 'components/TopBar/components/ThemeModeButton'
import PromoButton from 'components/TopBar/components/PromoButton'
import NetworkButton from 'components/TopBar/components/NetworkButton'
import NavButtons from 'components/TopBar/components/NavButtons'
import Divider from 'components/Divider'
import Spacer from 'components/Spacer'
import Label from 'components/Label'
import { RowFixed, RowBetween } from 'components/Row'
import { WrongNetworkToast, WrongRPCToast } from 'components/ToastPopup'
import PromoModalUser from 'components/PromoModalUser'

import bannerImg from 'assets/img/banner-image.webp'
import { images } from 'theme/config'

import { setReferral } from 'api'
// import ThemeSelector from './components/ThemeSelector'

const useQuery = (history) => {
  return new URLSearchParams(history?.location?.search)
}

const TopBar = ({
  setIsDark,
  languages,
  lang,
  setLang,
  isSidebar,
  showSidebar,
  setShowSidebar,
  setTheme,
}) => {
  const { ethereum } = window
  const location = useLocation()
  const history = useHistory()
  const ref = useRef(null)
  const { width } = useWindowSize()
  const [open, setOpen] = useState(false)
  const [isSearched, setIsSearched] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [openBanner, setOpenBanner] = useState(true)
  const [openLangMenu, setOpenLangMenu] = useState(false)
  const { isHackedUser, fetchBondDataList, bondContracts } =
    useContext(AccountContext)
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const networkId = useSelector(({ network }) => network.id)
  const vcashPrice =
    useSelector(({ application }) => application?.vcashPrice)?.[networkId] || 1
  const isDark = useSelector(({ application }) => application.isDark)
  const wallet = useSelector(({ user }) => user.wallet)
  const rpcError = useSelector(({ user }) => user.rpcError)
  const pendingSwitch = useSelector(({ user }) => user.pendingSwitch)
  const privateKey = useSelector(({ user }) => user.privateKey)
  const { chainId, error, account } = useWallet()
  const index = supportedChainIds.findIndex((id) => id === parseInt(networkId))
  const params = location.pathname.split('/')
  const mobileLayout = width < 961
  const isHomePage = params?.length > 1 && params?.[1] === 'home'
  const md = new MobileDetect(window.navigator.userAgent)
  const [handleReward] = useModal(<PromoModalUser />)

  const dispatch = useDispatch()
  const isWarning = privateKey ? false : index > -1 ? false : true
  const showWarning = pendingSwitch
    ? false
    : error
    ? true
    : !chainId
    ? false
    : supportedChainIds.includes(parseInt(ethereum?.chainId)) &&
      parseInt(networkId) !== chainId
  const isSupported = error ? false : true
  const networksArray = Object.keys(networks).map((key) =>
    Object.assign(networks[key], { chainId: parseInt(key) })
  )

  const query = useQuery(history)
  const referBy = query.get('ref')
  const networkQuery = query.get('network')

  useEffect(() => {
    dispatch(setPromoModal(true))
  }, [])

  useEffect(() => {
    setIsDark(isDark)
  }, [isDark])

  useEffect(() => {
    setShowBanner(isHackedUser)
  }, [isHackedUser])

  useEffect(() => {
    if (!mobileLayout) {
      setOpen(false)
    }
  }, [width])

  useEffect(() => {
    if (chainId) {
      if (md.mobile() || wallet === 'walletconnect') {
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log('ChainId changed')
          console.log(chainId)
        }
      }
      if (
        (md.mobile() || wallet === 'walletconnect') &&
        chainId !== networkId &&
        supportedChainIds.includes(chainId)
      ) {
        dispatch(
          changeNetwork({
            network: chainId,
            networkDetails: config[chainId],
          })
        )
      }
    }
  }, [chainId, networkId, wallet])

  useEffect(() => {
    const bodyEle = document.getElementById('body')
    const htmlEle = document.getElementById('html')
    if (htmlEle && bodyEle) {
      if (isDark) {
        bodyEle.style.height = '100%'
        bodyEle.style.background =
          'radial-gradient(circle at 16% 26%, rgba(11, 60, 58, 0), #003139 39%, #002a34 53%, #00252d 71%, #00252e 77%, #033239 92%), linear-gradient(to bottom, #083c39, #083c39)'
        htmlEle.style.background = '#002730'
      } else {
        bodyEle.style.background = '#f5f5f8'
        htmlEle.style.background = '#f5f5f8'
      }
    }
  }, [isDark])

  useEffect(() => {
    if (ethereum && ethereum.on) {
      chainId &&
        ethereum.request({ method: 'eth_chainId' }).then((chainId) => {
          if (!chainId) return
          const id = supportedChainIds.includes(parseInt(chainId))
            ? parseInt(chainId)
            : DEFAULT_NETWORK_ID
          if (!md.mobile() && networkQuery) {
            const networkFromQuery = networksArray.find(
              (network) => network?.name.toLowerCase() === networkQuery
            )
            if (!networkFromQuery) {
              dispatch(
                changeNetwork({
                  network: id,
                  networkDetails: config[id],
                })
              )
            }
          } else {
            dispatch(
              changeNetwork({
                network: id,
                networkDetails: config[id],
              })
            )
          }
        })
      const handleChainChanged = (chainId) => {
        const network = networks[parseInt(chainId)]
        if (network && history && typeof history.push === 'function') {
          history.push({
            search: `?network=${network?.name?.toLowerCase()}${
              referBy ? `&ref=${referBy}` : ''
            }`,
          })
        }
      }
      ethereum.on('chainChanged', handleChainChanged)
    }
  }, [chainId])

  useEffect(() => {
    if (!mobileLayout) {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [ref])

  useEffect(() => {
    if (isWarning || showWarning || !isSupported) {
      toast(<WrongNetworkToast />, {
        toastId: 'wrong-network',
        autoClose: 5000,
        closeOnClick: false,
      })
    }
    if (!isWarning && !showWarning && isSupported) {
      toast.dismiss('wrong-network')
    }
  }, [isWarning, showWarning, isSupported, ethereum?.chainId, networkId])

  useEffect(() => {
    if (rpcError && POLYGON_CHAINS.includes(chainId)) {
      toast(<WrongRPCToast />, {
        toastId: 'wrong-rpc',
        autoClose: 10000,
        closeOnClick: false,
      })
    } else {
      toast.dismiss('wrong-rpc')
    }
  }, [rpcError])

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const prevLocation = usePrevious(location)
  useEffect(() => {
    const currentRoute = location?.pathname?.split('/')?.[1]
    const prevRoute = prevLocation?.pathname?.split('/')?.[1]
    if (Object.keys(bondContracts)?.length > 0 && currentRoute !== prevRoute) {
      fetchBondDataList()
    }
  }, [location?.pathname, bondContracts])

  useEffect(() => {
    if (Object.keys(bondContracts)?.length > 0) {
      fetchBondDataList()
    }
  }, [networkId, bondContracts, vcashPrice])

  const useLocationChange = (action) => {
    const location = useLocation()
    const prevLocation = usePrevious(location)
    const currentRoute = location?.pathname?.split('/')?.[1]
    const prevRoute = prevLocation?.pathname?.split('/')?.[1]
    useEffect(() => {
      if (currentRoute !== prevRoute) {
        window.scrollTo(0, 0)
      }
      setShowSidebar(false)
      action(location, prevLocation)
    }, [location?.pathname, account])
  }

  useLocationChange((location, prevLocation) => {
    const prevQuery = new URLSearchParams(prevLocation?.search)
    const currentQuery = new URLSearchParams(location?.search)
    const refQuery = prevQuery?.get('ref')
    const networkQuery = currentQuery?.get('network')
    if (networkQuery) {
      refQuery &&
        !account &&
        history.replace({
          search:
            '?' +
            new URLSearchParams({
              network: networkQuery,
              ref: refQuery,
            }).toString(),
        })
    } else {
      refQuery &&
        !account &&
        history.replace({
          search:
            '?' +
            new URLSearchParams({
              ref: refQuery,
            }).toString(),
        })
    }
  })

  useEffect(() => {
    if (account) {
      setReferral({ referrer: null, user_id: account }) // replace later one week with next line
      // setReferral({ referrer: referBy ?? null, user_id: account })
    }
  }, [account, referBy])

  const MonoXLogo = ({ pos }) => (
    <StyledAccountButtonWrapper href="/" pos={pos}>
      <Logo src={images.logo3x} />
      <StyledLogoTitle>MonoX Protocol</StyledLogoTitle>
    </StyledAccountButtonWrapper>
  )

  const close = () => {
    setOpen(false)
    setOpenLangMenu(false)
  }

  if (/restricted|error/.test(params?.[1])) {
    return null
  }

  return (
    <>
      <StyledTopBar
        style={{
          marginBottom: '70px',
          background: isDark && (open ? '#0a3239' : !mobileLayout && 'transparent'),
        }}
        isDark={isDark}
        open={open}
        mobileLayout={mobileLayout}
        ref={ref}
      >
        {mobileLayout ? (
          <StyledTopBarInner
            content="flex-start"
            direction={'column'}
            open={open}
            isDark={isDark}
          >
            <Container height={64} content="space-between" direction="row">
              <MonoXLogo pos="relative" />
              <SearchBar isSearched={isSearched} setIsSearched={setIsSearched} />
              <RowFixed>
                <AccountButton mobile={true} isSidebar={isSidebar} />
                <div
                  style={{
                    height: '30px',
                    width: '30px',
                    paddingRight: '20px',
                  }}
                >
                  {open ? (
                    <CloseMenu onClick={close} data-testid="close" />
                  ) : (
                    <HamburgerIcon
                      onClick={() => setOpen(true)}
                      data-testid="open-menu"
                      style={{ marginLeft: '30px' }}
                    />
                  )}
                </div>
              </RowFixed>
            </Container>
            {open && (
              <>
                <Divider style={{ position: 'absolute', top: '67px' }} />
                <Container height={64} content="flex-end" direction="row">
                  {/* {isHackedUser ? <PromoButton promoLength="1" /> : ''} */}
                  <PromoButton promoLength="1" />
                  <Spacer size="lg" />
                  <Spacer size="lg" />
                  <NetworkButton isNetwork={!(isWarning || showWarning)} />
                  <Spacer size="lg" />
                  <ThemeModeButton setIsDark={setIsDark} />
                </Container>
                <Container content="flex-start" direction="column">
                  <MobileMenu
                    setLang={setLang}
                    lang={lang}
                    languages={languages}
                    close={close}
                    openLangMenu={openLangMenu}
                    setOpenLangMenu={setOpenLangMenu}
                  />
                </Container>
              </>
            )}
          </StyledTopBarInner>
        ) : (
          <StyledTopBarInner content="flex-start" direction="row">
            <RowBetween style={{ height: '100%' }}>
              {isSidebar ? (
                <SearchBar isSearched={isSearched} setIsSearched={setIsSearched} />
              ) : (
                <RowFixed>
                  {!isHomePage && (
                    <HamburgerIcon
                      style={{
                        marginTop: '4px',
                        fill: '#2ab886',
                        width: '25px',
                      }}
                      onClick={() => setShowSidebar(!showSidebar)}
                    />
                  )}
                  <Spacer size="sm" />
                  <MonoXLogo pos="relative" />
                </RowFixed>
              )}
              <RowFixed style={{ height: '100%' }}>
                {!isSidebar && (
                  <>
                    <NavButtons />
                    <Separator />
                  </>
                )}
                {/* {isHackedUser ? <PromoButton promoLength="1" /> : ''}
                {isHackedUser ? <Box /> : ''} */}
                {/* <PromoButton promoLength="1" /> */}
                <NetworkButton isNetwork={!(isWarning || showWarning)} />
                <Box />
                <ThemeModeButton setIsDark={setIsDark} />
                {/* <Box />
                <ThemeSelector setTheme={setTheme} /> */}
                <Box />
                <AccountButton isSidebar={isSidebar} mobile={false} />
                <Box />
                <LocaleButton
                  setLang={setLang}
                  lang={lang}
                  languages={languages}
                  openLangMenu={openLangMenu}
                  setOpenLangMenu={setOpenLangMenu}
                />
              </RowFixed>
            </RowBetween>
          </StyledTopBarInner>
        )}
      </StyledTopBar>
      {mobileLayout ? <div style={{ height: '135px' }}></div> : ''}
      {/* {isHomePage && showBanner && (
        <Banner img={bannerImg}>
          <Div>
            <Label
              size="21"
              weight="800"
              text="Accept the terms and get dMONO"
              align="center"
              color="#ffffff"
              style={{ margin: '15px 20px 15px 20px' }}
              // values={{
              //   mono: '$MONO',
              //   learnMore: (
              //     <>
              //       &nbsp;
              //       <StyledLink
              //         href={`/swap/${MAIN_CURRENCY?.symbol}/MONO?network=${networkName}`}
              //         target="_blank"
              //       >
              //         {ManualTransWord?.[intl.locale]?.learnMore}
              //       </StyledLink>
              //     </>
              //   ),
              // }}
              translateId="common.banner"
            />
          </Div>
          <StyledButton
            data-event="click"
            data-event-off="click"
            data-iscapture="true"
            data-testid="refer"
            onClick={handleReward}
          >
            {' '}
            <Label
              size="14"
              weight="800"
              translateId="home.cta.recently-added.button"
              text="Learn More"
              style={{ margin: '0 10px', color: '#9194fc' }}
            />
          </StyledButton>
          <div
            style={{
              position: 'absolute',
              right: 20,
              top: 13,
            }}
          >
            <CloseBanner
              onClick={() => setShowBanner(false)}
              data-testid="dismiss"
            />
          </div>
        </Banner>
      )} */}
      {isHomePage && openBanner && (
        <Banner img={bannerImg}>
          <Div>
            <Label
              size="21"
              weight="800"
              text="Buy MONO tokens here!"
              align="center"
              color="#ffffff"
              style={{ margin: '15px 20px 15px 20px' }}
              translateId="common.banner.mono"
              // values={{
              //   mono: '$MONO',
              //   learnMore: (
              //     <>
              //       &nbsp;
              //       <StyledLink
              //         href={`/swap/${MAIN_CURRENCY?.symbol}/MONO?network=${networkName}`}
              //         target="_blank"
              //       >
              //         {ManualTransWord?.[intl.locale]?.learnMore}
              //       </StyledLink>
              //     </>
              //   ),
              // }}
            />
          </Div>
          <CTA>
            <StyledButton
              data-event="click"
              data-event-off="click"
              data-iscapture="true"
              data-testid="refer"
              onClick={() => {
                window.open('https://www.huobi.com/en-us/exchange/mono_usdt')
              }}
            >
              {' '}
              <Label
                size="14"
                weight="800"
                text="Huobi"
                style={{ margin: '0 10px', color: '#9194fc' }}
              />
            </StyledButton>
            <StyledButton
              data-event="click"
              data-event-off="click"
              data-iscapture="true"
              data-testid="refer"
              onClick={() => {
                window.open('https://www.mexc.com/exchange/MONOX_USDT')
              }}
            >
              {' '}
              <Label
                size="14"
                weight="800"
                text="MEXC"
                style={{ margin: '0 10px', color: '#9194fc' }}
              />
            </StyledButton>
            <StyledButton
              data-event="click"
              data-event-off="click"
              data-iscapture="true"
              data-testid="refer"
              onClick={() => {
                window.open('https://monox.finance/swap/MATIC/MONO?network=polygon')
              }}
            >
              {' '}
              <Label
                size="14"
                weight="800"
                text="MonoX"
                style={{ margin: '0 10px', color: '#9194fc' }}
              />
            </StyledButton>
          </CTA>

          <div
            style={{
              position: 'absolute',
              right: 20,
              top: 13,
            }}
          >
            <CloseBanner
              onClick={() => setOpenBanner(false)}
              data-testid="dismiss"
            />
          </div>
        </Banner>
      )}
    </>
  )
}

const Box = styled.div`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  @media (max-width: 1367px) {
    height: 15px;
    width: 15px;
    min-height: 15px;
    min-width: 15px;
  }
  @media (max-width: 1024px) {
    height: 10px;
    width: 10px;
    min-height: 10px;
    min-width: 10px;
  }
`

const StyledTopBar = styled.div`
  height: ${(props) => (props.open ? 'auto' : '64')}px;
  transition: all 0.3s ease-out;
  padding: 0 68px 0 60px;
  @media (max-width: 1600px) {
    padding: 0 30px;
  }
  @media (max-width: 1024px) {
    padding: 0 15px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    box-shadow: ${({ theme }) => theme.shadows.topbar};
    padding:0 30px;
    margin-bottom:40px
  `};
  ${(props) => props.isDark && props.open && props.theme.darkMode}
  ${(props) =>
    props.mobileLayout &&
    `width: -webkit-fill-available;
    width: -moz-available;          
    width: fill-available;
    position:fixed;
    z-index: 99;
    opacity:1;
    ${!props.open && 'box-shadow:none'}`};
  background-color: ${(props) =>
    props.isDark ? '#0a3239' : props.theme.color.background.main};
`

const Logo = styled.img`
  width: 43px;
  height: 38px;
`

const StyledLogoTitle = styled.div`
  margin-left: 7px;
  font-size: 15px;
  font-weight: 800;
  color: ${({ theme }) => theme.color.font.primary};
  width: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none`}
`

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  height: ${(props) => (props.open ? 'auto' : props.theme.topBarSize)}px;
  justify-content: ${(props) => props.content};
  width: 100%;
  flex-direction: ${(props) => props.direction};
`

const StyledAccountButtonWrapper = styled.a`
  position: relative;
  @media (max-width: 1450px) {
    position: ${(props) => props.pos};
  }
  background-color: transparent;
  border: none;
  outline: none !important;
  text-decoration: none;
  align-items: center;
  display: flex;
  cursor: pointer;
`

const HamburgerIcon = styled(List)`
  color: ${({ theme }) => theme.color.font.primary};
  width: 30px;
  height: 30px;
  cursor: pointer;
`
const CloseMenu = styled(Close)`
  color: ${({ theme }) => theme.color.font.primary};
  width: 30px;
  height: 30px;
  margin-left: 30px;
`
const CloseBanner = styled(Close)`
  color: #ffffff;
  width: 25px;
  height: 25px;
  cursor: pointer;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  height: ${(props) => props.height}px;
  align-items: center;
  margin-left: auto;
  justify-content: ${(props) => props.content};
  flex-direction: ${(props) => props.direction};
`
const Banner = styled.div`
  background-image: url(${(props) => props?.img});
  position: relative;
  height: 175px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  margin-bottom: 70px;
  margin-top: -70px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1195px) {
    height: 220px;
  }
  @media (max-width: 750px) {
    height: 200px;
  }
  @media (max-width: 600px) {
    height: 230px;
  }
  @media (max-width: 500px) {
    height: 250px;
  }
  @media (max-width: 450px) {
    height: 270px;
  }
  @media (max-width: 400px) {
    height: 210px;
  }
`
const Separator = styled.div`
  border-left: 1px solid #d6d6d9;
  border-right: 1px solid #fcfcff;
  height: 22px;
  margin: 0 25px 0 6px;
  @media (max-width: 1600px) {
    margin: 0 14px 0 0;
  }
  @media (max-width: 1400px) {
    margin: 0 12px 0 0;
  }
  @media (max-width: 1300px) {
    margin: 0 13px 0 4px;
  }
  @media (max-width: 1250px) {
    margin: 0 19px 0 4px;
  }
`

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  min-width: max-content;
  padding: 5px;
  margin: 5px;
  height: 26px;
  border-radius: 13px;
  background-color: #fff;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-left: 10px;
  margin-top: 15px;
  `};
  @media (max-width: 500px) {
    margin-top: 10px;
  }
`

const Div = styled.div`
  @media (max-width: 1195px) {
    width: 475px;
  }
  @media (max-width: 600px) {
    width: auto;
  }
`

const CTA = styled.div`
  @media (min-width: 1200px) {
    width: 475px;
    display: flex;
  }
  @media (min-width: 1199px) {
    width: 475px;
  }
`

export default TopBar
